import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import store from "../store/index.js";
import DashboardLayout from "../layouts/Default_Dashboard.vue";
import Overview from "../views/Overview.vue";
import Profile from "../views/Profile.vue";
import Master from "../layouts/Master.vue";
import Children from "../layouts/Children.vue";
import PageNotFound from "../views/PageNotFound.vue"


Vue.use(VueRouter);

async function checkAuth(to, from, resolve, reject) {
  store.dispatch('setOverlay', true)
  await axios.get(`${process.env.VUE_APP_URL}/api/cek_token`, { 
      headers: { Authorization: `Bearer ${store.getters.isTokenUser}` } 
  }).then(response => {
      store.dispatch('setOverlay', false)
      // Token is valid, so continue
      resolve();
  }).catch(error => {
    store.dispatch('setOverlay', false)
      // There was an error so redirect
    router.push({ path: "/" });
  })
}

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/password/reset",
    name: "ForgetPassword",
    component: () =>
    import("../views/ForgetPassword.vue"),
  },
  {
    path: "/reset/password/:link",
    name: "ResetPassword",
    component: () =>
      import("../views/ResetPassword.vue"),
  },
  {
    path: "/admin",
    component: DashboardLayout,
    redirect: "/admin/dashboard",
    beforeEnter: checkAuth,
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: Children,
        redirect: "/admin/dashboard",
        beforeEnter: checkAuth,
        children: [
          {
            path: '',
            name: 'dashboard',
            component: Overview
          },
        ]
      },
      {
        path: 'master',
        name: 'USER MANAGEMENT',
        component: Children,
        redirect: "/admin/master",
        beforeEnter: checkAuth,
        children: [
          {
            path: '',
            name: 'USER MANAGEMENT',
            component: () => import("../views/Master/DataStore/DataStore.vue")
          },
          {
            path: ":datastore",
            component: Children,
            redirect: "/admin/master/datastore/overview",
            name: 'USER MANAGEMENT',
            children: [
              {
                path: 'overview',
                name: 'USER MANAGEMENT',
                component: () => import("../views/Master/DataStore/UserManagement/Overview.vue")
              },
              {
                path: 'entity',
                name: 'USER MANAGEMENT',
                component: () => import("../views/Master/DataStore/UserManagement/Entity.vue")
              },
              {
                path: 'application',
                name: 'USER MANAGEMENT',
                component: () => import("../views/Master/DataStore/UserManagement/Application.vue")
              },
              {
                path: 'group',
                name: 'USER MANAGEMENT',
                component: () => import("../views/Master/DataStore/UserManagement/GroupMaster.vue")
              },
              {
                path: 'menu-header',
                name: 'USER MANAGEMENT',
                component: () => import("../views/Master/DataStore/UserManagement/MenuHeader.vue")
              },
              {
                path: 'menu-item',
                name: 'USER MANAGEMENT',
                component: () => import("../views/Master/DataStore/UserManagement/MenuItem.vue")
              },
              {
                path: 'env-variabel',
                name: 'USER MANAGEMENT',
                component: () => import("../views/Master/DataStore/UserManagement/EnvVariable.vue")
              },
              
              {
                path: 'office',
                name: 'USER MANAGEMENT',
                component: () => import("../views/Master/DataStore/UserManagement/BranchOffice.vue")
              },
            ]
          },
          {
            path: ":user_mgt/management",
            component: Children,
            redirect: "/admin/master/user_mgt/management/overview",
            name: 'USER MANAGEMENT',
            children: [
              {
                path: 'overview',
                name: 'USER MANAGEMENT',
                component: () => import("../views/Master/UserManagement/OverviewUserManagement.vue")
              },
              {
                path: 'env-configuration',
                name: 'USER MANAGEMENT',
                component: () => import("../views/Master/UserManagement/EnvConfiguration.vue")
              },
              {
                path: 'users',
                name: 'USER MANAGEMENT',
                component: () => import("../views/Master/UserManagement/Users.vue")
              },
              {
                path: 'user-group',
                name: 'USER MANAGEMENT',
                component: () => import("../views/Master/UserManagement/UserGroup.vue")
              },
              {
                path: 'group-right',
                name: 'USER MANAGEMENT',
                component: () => import("../views/Master/UserManagement/GroupRight.vue")
              },
            ]
          }
        ]
      },
      {
        path: 'finance',
        name: 'finance',
        component: Children,
        redirect: "/admin/finance",
        beforeEnter: checkAuth,
        children: [
          {
            path: '',
            name: 'finance',
            component: () => import("../views/Finance/Finance.vue")
          },
          {
            path: ":ledger",
            component: Children,
            redirect: "/admin/finance/ledger/overview",
            name: 'finance',
            children: [
              {
                path: 'overview',
                name: 'finance',
                component: () => import("../views/Finance/Ledger/Index.vue")
              },
              {
                path: 'coa',
                name: 'finance',
                component: () => import("../views/Finance/Ledger/coa/Index.vue")
              },
              {
                path: 'ledger-year',
                name: 'finance',
                component: () => import("../views/Finance/Ledger/report_ledger/GeneralLedgerYear.vue")
              },
              {
                path: 'other-payment',
                name: 'finance',
                component: () => import("../views/Finance/Ledger/other_payment/OtherPayment.vue")
              },
              {
                path: 'journal-voucher',
                name: 'finance',
                component: () => import("../views/Finance/Ledger/journal/GeneralJournal.vue")
              },
            ]
          },
          {
            path: "budget-finance/:budget",
            component: Children,
            redirect: "/admin/finance/budget-finance/budget/overview",
            name: 'finance',
            children: [
              {
                path: 'overview',
                name: 'finance',
                component: () => import("../views/Finance/Budget/Index.vue")
              },
              {
                path: 'disbursement',
                name: 'finance',
                component: () => import("../views/Finance/Budget/disbursementnew/disbursement.vue")
              },
              {
                path: 'master-budget',
                name: 'finance',
                component: () => import("../views/Finance/Budget/master-budget/Index.vue")
              },
              {
                path: 'ledger-budget',
                name: 'finance',
                component: () => import("../views/Finance/Budget/budget/LedgerBudget.vue")
              },
              {
                path: 'report-budget',
                name: 'finance',
                component: () => import("../views/Finance/Budget/report/Index.vue")
              }
            ]
          },
          {
            path: "cash-bank-finance/:cash_bank",
            component: Children,
            redirect: "/admin/finance/cash-bank-finance/cash_bank/overview",
            name: 'finance',
            children: [
              {
                path: 'overview',
                name: 'finance',
                component: () => import("../views/Finance/cashbank/cashbank.vue")
              },
              {
                path: 'account-bank',
                name: 'finance',
                component: () => import("../views/Finance/cashbank/AccountBank.vue")
              },
              {
                path: 'mutasi-bank',
                name: 'finance',
                component: () => import("../views/Finance/cashbank/MutasiBank.vue")
              },
            ]
          },
          {
            path: ":jemaat",
            component: Children,
            redirect: "/admin/finance/jemaat/jemaat",
            name: 'finance',
            children: [
              {
                path: 'jemaat',
                name: 'finance',
                component: () => import("../views/Finance/Jemaat/Jemaat.vue")
              },
            ]
          },
          {
            path: "target-finance/:target",
            component: Children,
            redirect: "/admin/finance/target-finance/target/overview",
            name: 'finance',
            children: [
              {
                path: 'overview',
                name: 'finance',
                component: () => import("../views/Finance/Target/Index.vue")
              },
              {
                path: 'ledger-target',
                name: 'finance',
                component: () => import("../views/Finance/Target/ledger/LedgerTarget.vue")
              },
              {
                path: 'disbursement',
                name: 'finance',
                component: () => import("../views/Finance/Target/disbursement/Payment.vue")
              },
            ]
          },
          {
            path: "dashboard-finance/:dashboard",
            component: Children,
            redirect: "/admin/finance/dashboard-finance/dashboard/overview",
            name: 'finance',
            children: [
              {
                path: 'overview',
                name: 'finance',
                component: () => import("../views/Finance/Dashboard/Index.vue")
              },
            ]
          },
        ]
      },
      {
        path: 'profile',
        name: 'Profile',
        component: Profile
      }
    ]
  },
  { 
    path: "*", 
    component: DashboardLayout,
    redirect: "/PageNotFound",
    beforeEnter: checkAuth,
    children: [
      {
        path: 'PageNotFound',
        name: 'PageNotFound',
        component: PageNotFound
      }
    ]
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

let programmatic = false
;(['push', 'replace', 'go', 'back', 'forward']).forEach(methodName => {
  const method = router[methodName]
  router[methodName] = (...args) => {
    programmatic = true
    method.apply(router, args)
  }
})

router.beforeEach((to, from, next) => {
  // name is null for initial load or page reload
  if (from.name === null || programmatic) {
    // triggered bu router.push/go/... call
    // route as usual
    next()
  } else {
    // triggered by user back/forward 
    // do not route
    next(false)
  }
  programmatic = false // clear flag
})

export default router;